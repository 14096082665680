import * as React from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './RichTextEditor.module.scss';
import "react-quill/dist/quill.snow.css";


const getDefaults = () => {
  const editor = {
    /*
     * Quill modules to attach to editor
     * See https://quilljs.com/docs/modules/ for complete options
     */
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link'],
      ],
    },
    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    formats: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link'],
  };
  return editor;
};

const RichTextEditor = ({ id, onValueChange, value, placeholder, readOnly, className, editor }) => (
  <div className={classNames(styles.richTextEditorWrapper, 'monday-rich-text-editor', className)}>
    <ReactQuill
      id={id}
      onChange={onValueChange}
      modules={editor.modules}
      formats={editor.formats}
      value={value}
      placeholder={placeholder}
      readOnly={readOnly}
      className={classNames(styles.editor, 'monday-rich-text-editor__editor')}
    />
  </div>
);

RichTextEditor.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  editMode: PropTypes.bool,
  onValueChange: PropTypes.func,
  className: PropTypes.string,
};

RichTextEditor.defaultProps = {
  id: '',
  value: '',
  placeholder: '',
  style: null,
  readOnly: false,
  editMode: true,
  onValueChange: null,
  className: null,
  editor: getDefaults(),
};

export default RichTextEditor;

export const getItem = async (mondayClient, itemId) => {
  try {
    const query = `query($itemId: [ID!]) {
          items (ids: $itemId) {
            name
            column_values{
              column{
                title
              }
              value
              text
              id,
            }
          }
        }`;
    const variables = {
      itemId,
    };
    const response = await mondayClient.api(query, {
      variables,
    });
    return response.data.items[0];
  } catch (evt) {
    console.log(evt);
  }
};

import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css";
import { getItem } from "./services/mondayService";
import RichTextEditor from "./components/RichTextEditor";
import { Loader } from "monday-ui-react-core";

const monday = mondaySdk();

const columnValueText = (column_value) => {
  return `<p><strong>${column_value.column.title}</strong></p>
    <p>
    ${column_value.text}
    </p> 
    `;
};

const ItemView = (props) => {
  const [columnValueTexts, setColumnValueText] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchItemAndSetColumnValue = async () => {
      try {
        setLoading(true);
        const itemData = await getItemData(props.itemId);
        if (itemData) {
          setColumnValueText(itemData);
        } else {
          const initialColumnValueTexts = await initializeItemData(
            props.itemId,
          );
          setColumnValueText(initialColumnValueTexts);
        }
      } catch (error) {
        console.error("Error fetching or setting item:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchItemAndSetColumnValue();
  }, [props.itemId]);

  const getItemData = async (itemId) => {
    const res = await monday.storage.instance.getItem(itemId);
    return res.data.value || null;
  };

  const initializeItemData = async (itemId) => {
    const itemRes = await getItem(monday, itemId);
    const columnValues = itemRes.column_values.filter(
      (col_value) => col_value.value && col_value.text,
    );
    const initialColumnValueTexts = columnValues
      .map((columnValue) => columnValueText(columnValue))
      .join("<p><br></p>");
    await monday.storage.instance.setItem(itemId, initialColumnValueTexts);
    return initialColumnValueTexts;
  };

  return (
    <div className="overview-section-pulse-card-wrapper">
      {loading ? (
        <div className={"loader"}>
          <Loader size={40} />
        </div>
      ) : (
        <RichTextEditor
          className={"flex"}
          value={columnValueTexts}
          onValueChange={async (val) => {
            await monday.storage.instance.setItem(props.itemId, val);
            setColumnValueText(val);
          }}
        />
      )}
    </div>
  );
};

const App = () => {
  const [itemId, setItemId] = useState();
  useEffect(() => {
    monday.get("context").then((res) => {
      const resItemId = res.data.itemId;
      if (itemId !== resItemId) {
        setItemId(resItemId);
      }
    });
  }, [itemId]);

  return (
    <div className="overview-section">
      {itemId && <ItemView itemId={itemId} />}
    </div>
  );
};

export default App;
